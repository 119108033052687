import { ILatestArticlesBlockFields } from 'contentful-types'
import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'
import LatestArticlesBlock from './LatestArticlesBlock'

const ContentfulLatestArticlesBlock = withContentful<ILatestArticlesBlockFields>(LatestArticlesBlock, {
  blogCategory: (props) => props.fields.blogCategory,
  displayDate: (props) => props.fields.displayDate,
})

export default ContentfulLatestArticlesBlock
