import getConfig from 'next/config'
import { createClient } from 'contentful'

export type rssContent = {
  title: string
  link: string
  type?: string
  image?: string
  publishDate: string
}

// Get the latest articles of a specific category from a jsonRss content type
export const getLatestArticlesFromJsonRss = async (blogCategory, lang): Promise<rssContent[]> => {
  try {
    // Create the Contentful client
    const config = getConfig()?.publicRuntimeConfig
    const contentfulClient = createClient({
      space: config.CONTENTFUL_BLOG_BUILDER_SPACE,
      accessToken: config.CONTENTFUL_BLOG_BUILDER_DELIVERY_TOKEN,
      host: config.CONTENTFUL_BLOG_BUILDER_CLIENT_CONFIG.host,
      environment: config.CONTENTFUL_BLOG_BUILDER_CLIENT_CONFIG.environment,
    })

    // Fetch the articles data from a jsonRss content type
    const jsonRss: any = await contentfulClient.getEntries({
      content_type: 'jsonRss',
      'fields.categoryId': blogCategory,
      select: 'fields.rssContent',
      locale: `${lang}-CA`,
      limit: 1,
    })

    // Return the first three articles
    return jsonRss.items[0].fields.rssContent.slice(0, 3)
  } catch (error) {
    return null
  }
}

export default getLatestArticlesFromJsonRss
